export interface ShopShippingType {
    title: string;
    description: string|null;
}

export interface CheckoutShippingOptionType {
    shippingType: ShippingType;
    shippingPrice: ShippingPrice;
    title: string;
    description: string|null;
}

export interface ShippingType {
    id: number;
}

export interface ShippingPrice {
    price: number;
    currency: string;
}

export enum ShippingTypes {
    DELIVERY = 1,
    PICKUP = 2,
}

export interface ShippingInfoResponse {
    methods: CheckoutShippingOptionType[];
    hasManualDeliveryCheckPossibility: boolean;
}
