export function flattenObject(object: Record<string, unknown>, current?: string) {
    let result: Record<string, unknown> = {};

    for (const key in object) {
        const value = object[key];
        const newKey = current ? (current + "." + key) : key;

        if (value && typeof value === 'object') {
            if (Array.isArray(value)) {
                result[newKey] = value;
                continue;
            }

            result = {
                ...result,
                ...flattenObject(value as Record<string, unknown>, newKey),
            };
        } else {
            result[newKey] = value;
        }
    }

    return result;
}
