import { CartItem, CartItemOption, CartItemOptions }                                    from '@/types/cart';
import { ShopCode }                                                                     from '@/types/globals';
import { ConfigurableProduct, ConfigurableProductWithVariants, ProductAnalyticsObject } from '@/types/gtm';
import { captureMessage }                                                               from '@/utils/error-logger';
import Logger, { LogLevel }                                                             from '@/utils/logger';
import { flattenObject }                                                                from '@/utils/objects';

/**
 * Hard coded labels for all configurable products, their materials and variants
 */
export const configurableProducts: Record<number, ConfigurableProduct | ConfigurableProductWithVariants> = {
    25:  {
        label:     'Window',
        materials: {
            132: {
                834:   'PVC',
                4166:  'PVC/Aluminium',
                835:   'Wood',
                836:   'Wood/Aluminium',
                13247: 'Aluminium',
            },
        },
    },
    874: {
        label:     'Roof Window',
        materials: {
            2414: {
                13679: 'Wood',
                13680: 'PVC',
            },
        },
    },
    10:  {
        label:     'Balcony Door',
        materials: {
            65: {
                466:   'PVC',
                4192:  'PVC/Aluminium',
                467:   'Wood',
                468:   'Wood/Aluminium',
                13248: 'Aluminium',
            },
        },
    },
    633: {
        label:            'Slide Door',
        variantAttribute: 865,
        variants:         {
            4234:  {
                label:     'Tilt-and-slide Door',
                materials: {
                    867: {
                        4240: 'PVC',
                        4241: 'PVC/Aluminium',
                        4242: 'Wood',
                        4243: 'Wood/Aluminium',
                    },
                },
            },
            13580: {
                label:     'Smart-Slide Door',
                materials: {
                    2401: {
                        13581: 'PVC',
                    },
                },
            },
            4233:  {
                label:     'Lift-and-slide Door',
                materials: {
                    866: {
                        4236:  'PVC',
                        4237:  'PVC/Aluminium',
                        4238:  'Wood',
                        4239:  'Wood/Aluminium',
                        13524: 'Aluminium',
                    },
                },
            },
            4235:  {
                label:     'Bi-Folding-Door',
                materials: {
                    868: {
                        4244: 'PVC',
                        4245: 'PVC/Aluminium',
                        4246: 'Wood',
                        4247: 'Wood/Aluminium',
                    },
                },
            },
        },
    },
    614: {
        label:     'Front Door',
        materials: {
            547: {
                2656:  'PVC',
                2655:  'Aluminium',
                10612: 'Aluminium',
                7914:  'Aluminium',
                2654:  'Wood',
                13246: 'Wood',
            },
        },
    },
    869: {
        label:     'Front Door',
        materials: {
            2103: {
                9561:  'PVC',
                9563:  'Aluminium',
                9562:  'Wood',
                12912: 'Wood',
            },
        },
    },
    640: {
        label:     'Canopy',
        materials: {
            1299: {
                5618: 'Glass',
            },
            1253: {
                5508: 'Wood',
            },
            1257: {
                5512: 'Glass',
            },
        },
    },
    873: {
        label:     'Back Door',
        materials: {
            2405: {
                13597: 'Steel',
                13598: 'PVC',
                13599: 'PVC/Aluminium',
            },
        },
    },
    868: {
        label:     'Shutter',
        materials: {},
    },
    875: {
        label:     'Pergola',
        materials: {},
    },
    5:   {
        label:     'Int. Window Sill',
        materials: {
            434: {
                8647: 'PVC',
                2112: 'Werzalit',
                2111: 'Werzalit',
            },
        },
    },
    6:   {
        label:     'Ext. Window Sill',
        materials: {},
    },
    877: {
        label:     'Venetian Blinds',
        materials: {},
    },
    4:   {
        label:     'Cover Profile',
        materials: {
            5: {
                234: 'PVC',
                235: 'Wood',
            },
        },
    },
    878: {
        label:     'Garage Door',
        materials: {},
    },
    879: {
        label:     'Awning',
        materials: {},
    },
};

/**
 * Products that have no "item_category2" or "item_variant" (material and variant attributes).
 *
 * They are still configurable products, but for GTM, we construct those cart line item objects more like we do for
 * accessory products.
 */
export const configurableAccessories = [868, 875, 6, 877, 878, 879];

export const DEBUG_PREFIX = 'GTM: ';

export type AuthenticationType = 'direct' | 'checkout' | 'saving cart' | 'google' | 'login link';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function logAndCapture(msg: string, obj: Record<string, any> | null): void {
    Logger.log(
        LogLevel.DEBUG,
        DEBUG_PREFIX + msg,
        obj ? flattenObject(obj) : {},
    );
    captureMessage(msg + ': ' + JSON.stringify(obj));
}

/**
 * Simple TypeScript assertion function to determine if a product has variants or not
 */
export function isConfigurableProductWithVariant(
    product: ConfigurableProduct | ConfigurableProductWithVariants,
): product is ConfigurableProductWithVariants {
    return 'variants' in product && 'variantAttribute' in product;
}

/**
 * Generate a cart line item object for the GTM eCommerce dataLayer, from an accessory product
 */
export function getAnalyticsObjectForAccessory(
    productId: number,
    productName: string,
    position: number,
    quantity: number,
    price: number,
    currency: string,
): ProductAnalyticsObject {
    let categoryName = 'Accessories';

    if (configurableAccessories.includes(productId)) {
        const product = configurableProducts[productId];
        productName = product.label;
        categoryName = product.label;
    }

    return {
        index:          position,
        item_id:        `${productId}`,
        item_name:      productName,
        item_category:  categoryName,
        item_category2: null,
        item_variant:   null,
        quantity,
        price,
        currency,
    };
}

/**
 * Get the configured product variant
 */
export function getProductVariant(
    variant: number | null,
    product: ConfigurableProduct | ConfigurableProductWithVariants,
): ConfigurableProduct | null {
    if (!variant || !isConfigurableProductWithVariant(product)) {
        return null;
    }

    if (!(variant in product.variants)) {
        return null;
    }

    return product.variants[variant];
}

/**
 * Generate a cart line item object for the GTM eCommerce dataLayer, from a configurable product
 */
export function getAnalyticsObjectForProduct(
    productId: number,
    productName: string,
    materialAttributeId: number,
    material: number,
    variant: number | null,
    position: number,
    quantity: number,
    price: number,
    currency: string,
): ProductAnalyticsObject | null {
    const product = productId in configurableProducts ? configurableProducts[productId] : null;

    if (product === null) {
        return getAnalyticsObjectForAccessory(productId, productName, position, quantity, price, currency);
    }

    const productHasVariants = isConfigurableProductWithVariant(product);
    if (
        (variant && !productHasVariants) ||
        (!variant && productHasVariants)
    ) {
        Logger.log(
            LogLevel.DEBUG,
            DEBUG_PREFIX + 'Can not construct object for cart item, invalid arguments',
            {
                productId,
                productName,
                materialAttributeId,
                material,
                variant,
                position,
                quantity,
                price,
                currency,
                productHasVariants,
            },
        );
        return null;
    }

    const productVariant = getProductVariant(variant, product);

    // Get the label for the configured variant
    const variantLabel = productVariant ? productVariant.label : null;

    // Get the label for the configured material
    const materialLabel = productVariant ?
        productVariant.materials[materialAttributeId][material] :
        (product as ConfigurableProduct).materials[materialAttributeId][material];

    return {
        index:          position,
        item_id:        `${productId}`,
        item_name:      product.label,
        item_category:  product.label,
        item_category2: materialLabel,
        item_variant:   variantLabel,
        quantity,
        price,
        currency,
    };
}

/**
 * Get the value from the cart item attribute for product variant
 */
export function getVariantFromCartItem(
    product: ConfigurableProduct | ConfigurableProductWithVariants,
    options: CartItemOptions,
): number | null {
    const productHasVariants = isConfigurableProductWithVariant(product);
    if (!productHasVariants) {
        return null;
    }

    for (const key in options) {
        const option = options[key];
        if (parseInt(option.featureId + '', 10) === parseInt(product.variantAttribute + '', 10)) {
            return option.valueId;
        }
    }

    return null;
}

/**
 * Get the cart item attribute for product material
 */
export function getItemOptionForMaterial(
    product: ConfigurableProduct | ConfigurableProductWithVariants,
    options: CartItemOptions,
    variant: number | null,
): CartItemOption | null {
    const productHasVariants = isConfigurableProductWithVariant(product);

    if (
        (productHasVariants && !variant) ||
        (!productHasVariants && variant)
    ) {
        return null;
    }

    const materials = productHasVariants ?
        product.variants[variant!].materials : product.materials;

    for (const key in options) {
        const option = options[key];
        if (option.featureId && option.featureId in materials) {
            return option;
        }
    }

    return null;
}

/**
 * Get eCommerce object for each cart item
 */
export function getAnalyticsArrayForCartItems(items: CartItem[]): ProductAnalyticsObject[] {
    const cartItems: ProductAnalyticsObject[] = [];

    items.forEach(cartItem => {
        if (!cartItem.productId || !cartItem.name || !cartItem.options || !cartItem.quantity || !cartItem.discountedPrice) {
            const message = 'Cart item has some missing info, can not add to GTM eCommerce datalayer';
            logAndCapture(message, { cartItem });

            return;
        }

        const product = cartItem.productId in configurableProducts ?
            configurableProducts[cartItem.productId] : null;

        if (!product || configurableAccessories.includes(cartItem.productId)) {
            const gtmObject = getAnalyticsObjectForAccessory(
                cartItem.productId,
                cartItem.internalTitle || cartItem.name,
                cartItem.position,
                cartItem.quantity,
                cartItem.discountedPrice,
                'EUR',
            );

            cartItems.push(gtmObject);
            Logger.log(
                LogLevel.DEBUG,
                DEBUG_PREFIX + 'Added accessory product to eCommerce items list',
                flattenObject({ gtmObject, product }),
            );

            return;
        }

        const variant = getVariantFromCartItem(product, cartItem.options);
        const materialOption = getItemOptionForMaterial(product, cartItem.options, variant);

        let materialAttributeId: number | null;
        let material: number | null;

        if (!materialOption && window.shopShortcode === ShopCode.HTDE) {
            materialAttributeId = 2103;
            material = 9563;
        } else if (materialOption) {
            materialAttributeId = materialOption.featureId;
            material = materialOption?.valueId || null;
        } else {
            const message = 'Material attribute not found in cart item';
            const obj = Object.assign({}, cartItem, product, { variant });
            logAndCapture(message, obj);

            return;
        }

        if (!material || !materialAttributeId) {
            const message = 'Material not found in configurator product';
            const obj = Object.assign({}, cartItem, product, { variant, material });
            logAndCapture(message, obj);

            return;
        }

        const gtmObject = getAnalyticsObjectForProduct(
            cartItem.productId,
            cartItem.internalTitle || cartItem.name,
            materialAttributeId,
            material,
            variant,
            cartItem.position,
            cartItem.quantity,
            cartItem.discountedPrice,
            'EUR',
        );

        if (gtmObject) {
            cartItems.push(gtmObject);
            Logger.log(
                LogLevel.DEBUG,
                DEBUG_PREFIX + 'Added product to eCommerce items list',
                flattenObject({ gtmObject, product }),
            );
        } else {
            const message = 'Could not create GTM object for cart item';
            const obj = Object.assign({}, { cartItem }, { product });
            logAndCapture(message, obj);
        }
    });

    return cartItems;
}

export function buildGtmProductItemByParams(
    productId: number,
    productName: string,
    options: CartItemOptions,
    price: number,
    quantity: number,
    position?: number,
): ProductAnalyticsObject | null {
    const product = productId in configurableProducts ? configurableProducts[productId] : null;

    if (product === null || configurableAccessories.includes(productId)) {
        return getAnalyticsObjectForAccessory(productId, productName, position || 0, quantity, price, 'EUR');
    }

    const variant = getVariantFromCartItem(product, options);
    const materialOption = getItemOptionForMaterial(product, options, variant);

    if (!materialOption) {
        const message = 'Material attribute not found in configurator product';
        const obj = Object.assign({}, product, { variant });
        logAndCapture(message, obj);

        return null;
    }

    const materialAttributeId = materialOption.featureId;
    const material = materialOption?.valueId || null;

    if (!material || !materialAttributeId) {
        const message = 'Material not found';
        const obj = Object.assign({}, product, { variant, material });
        logAndCapture(message, obj);

        return null;
    }

    return getAnalyticsObjectForProduct(
        productId,
        productName,
        materialAttributeId,
        material,
        variant,
        position || 0,
        quantity,
        price,
        'EUR',
    );
}
