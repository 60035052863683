// Google Tag Manager
import {
    CartItem,
    CartItemOptions,
    CheckoutThankYouData,
}                           from '@/types/cart';
import { ShopCode }         from '@/types/globals';
import {
    EcommerceAnalyticsObject,
    EcommercePaymentAnalyticsObject,
    EcommercePurchaseAnalyticsObject,
    EcommerceShippingAnalyticsObject,
    ProductAnalyticsObject,
}                           from '@/types/gtm';
import { PaymentMethod }    from '@/types/payments';
import { ShippingTypes }    from '@/types/shipping';
import {
    AuthenticationType,
    buildGtmProductItemByParams,
    configurableAccessories,
    configurableProducts,
    DEBUG_PREFIX,
    getAnalyticsArrayForCartItems,
    getAnalyticsObjectForAccessory,
    getAnalyticsObjectForProduct,
    getItemOptionForMaterial,
    getVariantFromCartItem,
    logAndCapture,
} from '@/utils/gtm/utilities';
import Logger, { LogLevel } from '@/utils/logger';
import { flattenObject }    from '@/utils/objects';

function pushDataLayer(dataLayer: Record<string, unknown>): void {
    // In case the dataLayer does not exist, the GTM script may not have loaded (f.e. user's browser may have blocked it)
    if (!window.dataLayer) {
        Logger.log(LogLevel.DEBUG, DEBUG_PREFIX + 'No dataLayer exists, skipping execution.');
        return;
    }

    Logger.table(LogLevel.DEBUG, DEBUG_PREFIX + 'Pushing datalayer', flattenObject(dataLayer));
    window.dataLayer.push(dataLayer);
}

/**
 * Push a eCommerce dataLayer to Google Tag Manager
 */
export function pushEcommerceEvent(
    event: 'view_cart' | 'remove_from_cart' | 'begin_checkout',
    items: CartItem[],
    cartAmount?: number,
): void {
    // Collect all cart items
    const cartItems: ProductAnalyticsObject[] = [];
    if (items.length) {
        cartItems.push(...getAnalyticsArrayForCartItems(items));
    }

    // Construct the eCommerce part of the dataLayer
    const ecommerce: EcommerceAnalyticsObject = {
        items:    cartItems,
        value:    cartAmount || 0,
        currency: 'EUR',
    };

    // Push the datalayer
    const dataLayer = {
        event,
        ecommerce,
    };

    pushDataLayer(dataLayer);
}

type NonNullable<T> = Exclude<T, null | undefined>;
type PurchaseData = NonNullable<CheckoutThankYouData['gtm']>

export function pushEcommercePurchaseEvent(
    items: CartItem[],
    cartAmount: number,
    purchaseData: PurchaseData,
) {
    // Collect all cart items
    const cartItems: ProductAnalyticsObject[] = [];
    if (items.length) {
        cartItems.push(...getAnalyticsArrayForCartItems(items));
    }

    // Construct the eCommerce part of the dataLayer
    const ecommerce: EcommercePurchaseAnalyticsObject = {
        items:                 cartItems,
        value:                 cartAmount || 0,
        currency:              'EUR',
        transaction_id:        purchaseData.orderNumber,
        coupon:                purchaseData.coupon!,
        tax:                   purchaseData.tax,
        shipping:              purchaseData.shipping,
        customer_email:        purchaseData.customerEmail,
        customer_phone:        purchaseData.customerPhone!,
        customer_email_sha256: purchaseData.customerEmailSha256,
    };

    // Push the datalayer
    const dataLayer = {
        event: 'purchase',
        ecommerce,
    };

    pushDataLayer(dataLayer);
}

export function pushEcommerceAddShippingInfoEvent(
    shippingType: ShippingTypes,
    items: CartItem[],
    cartAmount: number,
) {
    // Collect all cart items
    const cartItems: ProductAnalyticsObject[] = [];
    if (items.length) {
        cartItems.push(...getAnalyticsArrayForCartItems(items));
    }

    // Construct the eCommerce part of the dataLayer
    const ecommerce: EcommerceShippingAnalyticsObject = {
        items:         cartItems,
        value:         cartAmount,
        currency:      'EUR',
        shipping_tier: shippingType === ShippingTypes.DELIVERY ? 'Delivery' : 'Pickup',
    };

    // Push the datalayer
    const dataLayer = {
        event: 'add_shipping_info',
        ecommerce,
    };

    pushDataLayer(dataLayer);
}

export function pushEcommerceAddPaymentInfoEvent(
    paymentMethod: PaymentMethod | string,
    items: CartItem[],
    cartAmount: number,
) {
    const cartItems: ProductAnalyticsObject[] = [];
    if (items.length) {
        cartItems.push(...getAnalyticsArrayForCartItems(items));
    }

    let paymentType = 'Bank transfer';
    if (typeof paymentMethod === 'string') {
        paymentType = paymentMethod;
    } else {
        switch (paymentMethod.paymentSourceSlug) {
            case 'paypal':
                paymentType = 'PayPal';
                break;
            case 'credit-card':
                paymentType = 'CreditCard';
                break;
            case 'b2b-invoice':
                paymentType = 'Billie';
                break;
            case 'invoice':
                paymentType = 'Invoice';
                break;
            case 'direct-debit':
                paymentType = 'Debit charge';
                break;
            case 'direct-bank-transfer':
                paymentType = 'Direct bank transfer';
                break;
            case 'targobank':
                paymentType = 'TargoBank';
                break;
            case 'bank-transfer':
                paymentType = 'Bank transfer';
                break;
        }
    }

    // Construct the eCommerce part of the dataLayer
    const ecommerce: EcommercePaymentAnalyticsObject = {
        items:        cartItems,
        value:        cartAmount,
        currency:     'EUR',
        payment_type: paymentType,
    };

    // Push the datalayer
    const dataLayer = {
        event: 'add_payment_info',
        ecommerce,
    };

    pushDataLayer(dataLayer);
}

export function pushCartEditEvent(
    cartItem: CartItem,
): void {
    let productName = cartItem.internalTitle;

    if (cartItem.productId) {
        const product = cartItem.productId in configurableProducts ?
            configurableProducts[cartItem.productId] : null;

        if (product) {
            productName = product.label;
        }
    }

    const dataLayer = {
        event:        'cart_edit',
        product_name: productName,
    };

    pushDataLayer(dataLayer);
}

export function pushConfiLoadEvent(
    productId: number,
    productName: string,
): void {
    const product = productId in configurableProducts ? configurableProducts[productId] : null;

    if (product) {
        productName = product.label;
    }

    const dataLayer = {
        event:         'page_view',
        page_location: window.location.pathname + window.location.search + window.location.hash,
        page_referrer: document.referrer,

        'page-type':        'Configurator',
        'header-type':      'No Header',
        'product-material': 'None',
        'content-grouping': productName,
        'page-focus':       'Configurator',
        'page-intent':      'Transactional',
    };

    pushDataLayer(dataLayer);
}

export function pushConfiAddToCartEvent(
    productId: number,
    productName: string,
    options: CartItemOptions,
    price: number,
): void {
    const product = productId in configurableProducts ?
        configurableProducts[productId] : null;

    if (configurableAccessories.includes(productId)) {
        const gtmObject = getAnalyticsObjectForAccessory(
            productId,
            productName,
            0,
            1,
            +price,
            'EUR',
        );

        const dataLayer = {
            event:     'add_to_cart',
            ecommerce: {
                items:    [gtmObject],
                value:    +price,
                currency: 'EUR',
            },
        };

        Logger.log(
            LogLevel.DEBUG,
            DEBUG_PREFIX + 'Created dataLayer for adding configurable accessory product to cart',
            flattenObject({ gtmObject, product }),
        );

        pushDataLayer(dataLayer);

        return;
    }

    if (!product) {
        const message = 'Could not create GTM object for configurator product';
        logAndCapture(message, product);

        return;
    }

    const variant = getVariantFromCartItem(product, options);
    const materialOption = getItemOptionForMaterial(product, options, variant);

    let materialAttributeId: number | null;
    let material: number | null;

    if (!materialOption && window.shopShortcode === ShopCode.HTDE) {
        materialAttributeId = 2103;
        material = 9563;
    } else if (materialOption) {
        materialAttributeId = parseInt(materialOption.featureId + '', 10);
        material = parseInt(materialOption.valueId + '', 10);
    } else {
        const message = 'Material attribute not found in configurator product';
        const obj = Object.assign({}, product, options, { variant });
        logAndCapture(message, obj);

        return;
    }

    if (!material || !materialAttributeId) {
        const message = 'Material not found om configurator product';
        const obj = Object.assign({}, product, { variant, material });
        logAndCapture(message, obj);

        return;
    }

    const gtmObject = getAnalyticsObjectForProduct(
        productId,
        productName,
        materialAttributeId,
        material,
        variant,
        0,
        1,
        +price,
        'EUR',
    );

    if (!gtmObject) {
        const message = 'Could not generate GTM object structure for configurable product';
        logAndCapture(message, product);

        return;
    }

    const dataLayer = {
        event:     'add_to_cart',
        ecommerce: {
            items:    [gtmObject],
            value:    +price,
            currency: 'EUR',
        },
    };

    Logger.log(
        LogLevel.DEBUG,
        DEBUG_PREFIX + 'Created dataLayer for adding configurable product to cart',
        flattenObject({ gtmObject, product }),
    );

    pushDataLayer(dataLayer);
}

export function pushConfiSwitchStepEvent(
    step: number,
    productId: number,
    productName: string,
): void {
    const product = productId in configurableProducts ?
        configurableProducts[productId] : null;

    if (product) {
        productName = product.label;
    }

    const dataLayer = {
        event:                'configurator_step_' + step,
        product_configurator: productName,
    };

    pushDataLayer(dataLayer);
}

export function pushConfiAbandonEvent(
    productId: number,
    productName: string,
    lastAction: string,
): void {
    const product = productId in configurableProducts ?
        configurableProducts[productId] : null;

    if (product) {
        productName = product.label;
    }

    const dataLayer = {
        event:       'form_abandonment',
        form_name:   'Configurator_' + productName.replace(/ /g, '-'),
        last_action: lastAction,
    };

    pushDataLayer(dataLayer);
}

export function pushConfiErrorEvent(errorText: string): void {
    const dataLayer = {
        event:         'form_error',
        error_message: errorText,
    };

    pushDataLayer(dataLayer);
}

export function pushConfiTooltipEvent(tooltipLabel: string): void {
    const dataLayer = {
        event:           'tooltip_configurator',
        tooltip_element: tooltipLabel,
    };

    pushDataLayer(dataLayer);
}

export function pushAccountPageViewEvent(): void {
    const dataLayer = {
        event:         'page_view',
        page_location: window.location.pathname + window.location.search + window.location.hash,
        page_referrer: document.referrer,

        'page-type':        'Account',
        'header-type':      'No Header',
        'product-material': 'None',
        'content-grouping': 'General',
        'page-focus':       'Account',
        'page-intent':      'Informational',
    };

    pushDataLayer(dataLayer);
}

export function pushAccountLoginEvent(method: AuthenticationType): void {
    const dataLayer = {
        event: 'login',
        method,
    };

    pushDataLayer(dataLayer);
}

export function pushAccountRegisterEvent(method: AuthenticationType): void {
    const dataLayer = {
        event: 'sign_up',
        method,
    };

    pushDataLayer(dataLayer);
}

export function pushAccountPromotionEvent(activate: boolean): void {
    const dataLayer = {
        event: activate ? 'activate_promotion' : 'deactivate_promotion',
    };

    pushDataLayer(dataLayer);
}

export function pushNewsletterSignupEvent(location: 'pop_up' | 'footer'): void {
    const dataLayer = {
        event:             'newsletter_sign_up',
        'signup_location': location,
    };

    pushDataLayer(dataLayer);
}

export function pushMainNavEvent(elementLabel: string): void {
    const dataLayer = {
        event:   'megadropdown_click',
        element: elementLabel.trim().replace(/\p{C}/gu, ''),
    };

    pushDataLayer(dataLayer);
}

export function pushCheckoutPageViewEvent(location: string): void {
    const dataLayer = {
        event:         'page_view',
        page_location: location,
        page_referrer: document.referrer,

        'page-type':        'Checkout',
        'header-type':      'No Header',
        'product-material': 'None',
        'content-grouping': 'General',
        'page-focus':       'Checkout',
        'page-intent':      'Transactional',
    };

    pushDataLayer(dataLayer);
}

export function pushCartExitModalShownEvent(): void {
    const dataLayer = {
        event:   'cart_modal_impression',
    };

    pushDataLayer(dataLayer);
}

export function pushCartExitModalSaveCartEvent(): void {
    const dataLayer = {
        event:   'cart_modal_click_save',
    };

    pushDataLayer(dataLayer);
}

export function pushCartProductAddedEvent(
    productId: number,
    productName: string,
    options: CartItemOptions,
    price: number,
    quantity: number,
): void {
    const gtmObject = buildGtmProductItemByParams(productId, productName, options, price, quantity);
    if (gtmObject === null) {
        return;
    }

    const dataLayer = {
        event:     'add_to_cart',
        ecommerce: {
            items:    [gtmObject],
            value:    price,
            currency: 'EUR',
        },
    };

    pushDataLayer(dataLayer);
}

export function pushProductViewEvent(product: GtmProductInfo): void {
    const gtmObject = buildGtmProductItemByParams(
        product.productId,
        product.productName,
        product.options,
        product.price,
        1,
    );

    if (gtmObject === null) {
        return;
    }

    const dataLayer = {
        event:     'view_item',
        ecommerce: {
            items:    [gtmObject],
            value:    product.price,
            currency: 'EUR',
        },
    };

    pushDataLayer(dataLayer);
}

export function pushProductListViewEvent(gtmProductListInfo: GtmProductsListData[]): void {
    gtmProductListInfo.forEach(function(gtmListData) {
        const gtmObjects: ProductAnalyticsObject[] = [];

        gtmListData.items.forEach(function(gtmProductInfo, index) {
            const gtmObject = buildGtmProductItemByParams(
                gtmProductInfo.productId,
                gtmProductInfo.productName,
                gtmProductInfo.options,
                gtmProductInfo.price,
                1,
                index,
            );

            if (gtmObject === null) {
                return;
            }

            gtmObjects.push(gtmObject);
        });

        if (gtmObjects.length === 0) {
            return;
        }

        const dataLayer = {
            event:     'view_item_list',
            ecommerce: {
                item_list_id:   gtmListData.itemListId,
                item_list_name: gtmListData.itemListName,
                items:          gtmObjects,
                value:          0,
                currency:       'EUR',
            },
        };

        pushDataLayer(dataLayer);
    });
}

export function pushVoucherAddedToBasketEvent(
    code: string,
    name: string,
    errorCode?: string,
): void {
    const dataLayer = {
        event:        'voucher_add_success',
        voucher_code: code,
        voucher_name: name,
    };

    if (typeof errorCode === 'string' && errorCode !== '') {
        dataLayer.event = 'voucher_add_error';
        dataLayer['error_reason'] = errorCode;
    }

    pushDataLayer(dataLayer);
}

export function pushSearchRequest(searchQuery: string): void {
    const dataLayer = {
        event:       'search_request',
        search_term: searchQuery,
    };

    pushDataLayer(dataLayer);
}

export function pushSearchNoResult(searchQuery: string): void {
    const dataLayer = {
        event:       'search_result_error',
        search_term: searchQuery,
    };

    pushDataLayer(dataLayer);
}

export function pushForgotPasswordEvent(isLoginLink: boolean) {
    const dataLayer = {
        event: isLoginLink ? 'login_link_sent' : 'password_reset',
    };

    pushDataLayer(dataLayer);
}

// These are executed from the configurator JS, and so must be available in the global scope.
// @ts-ignore
window.pushConfiAddToCartEvent = pushConfiAddToCartEvent;
// @ts-ignore
window.pushConfiSwitchStepEvent = pushConfiSwitchStepEvent;
// @ts-ignore
window.pushConfiAbandonEvent = pushConfiAbandonEvent;
// @ts-ignore
window.pushConfiErrorEvent = pushConfiErrorEvent;
// @ts-ignore
window.pushConfiTooltipEvent = pushConfiTooltipEvent;
